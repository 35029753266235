<template>
    <div class="footer">
        <div class="links links--border">
            <a
                v-for="item in mainlinks"
                class="links__item links__item--black"
                :href="item.href"
                :key="item.href"
                v-text="item.text"
            />
        </div>
        <div class="links links--padding links--border">
            <p
                v-for="(item, index) in sectionLinks"
                class="section-links"
                :key="index"
            >
                <a
                    v-for="link in item"
                    class="links__item links__item--grey"
                    :href="link.href"
                    :key="link.href"
                    v-text="link.text"
                />
            </p>
        </div>
        <div class="footer__credits">
            <div class="skolkovo">
                <div class="skolkovo__logo">
                    <a href="https://sk.ru/">
                        <sk-logo class="skolkovo__svg" />
                    </a>
                </div>
                <div class="skolkovo__text">Резидент инновационного центра «Сколково»</div>
            </div>
            <div class="social">
                <div
                    style="display: none;"
                    v-html="SocialIcons"
                />
                <a
                    v-for="(item, index) in social"
                    class="social__item"
                    :key="index"
                    :href="item.link"
                    target="_blank"
                >
                    <svg
                        slot="icon"
                        class="social__svg"
                    >
                        <use :xlink:href="`#${item.alias}`" />
                    </svg>
                </a>
            </div>
        </div>
        <div class="credits">
            {{ getDate() }} Workle Pro — официальная интернет-работа
        </div>

        <div class="recommendation-technologies recommendation-technologies--padding-top">
            На информационном ресурсе при применении информационных технологий предоставления информации <a class="recommendation-technologies__link" target="_blank" href="https://advt.pro/c/recommendation-technologies">осуществляется сбор, систематизация и анализ сведений</a>, относящихся к предпочтениям пользователей сети "Интернет", находящихся на территории Российской Федерации
        </div>
    </div>
</template>

<script>
    import SocialIcons from '!!svg-inline-loader!Assets/sprites/social-icons.svg'
    import SkLogo from 'Components/assets/sk-logo-home.svg?component'

    export default {
        name: 'HomeFooter',
        components: {
            SkLogo
        },
        data () {
            return {
                SocialIcons,
                mainlinks: [
                    {
                        text: 'О нас',
                        href: '/content/workle.pro/'
                    },
                    {
                        text: 'Для кого',
                        href: '/promo/for-whom/'
                    },
                    {
                        text: 'Партнерам',
                        href: 'https://partner.ng.workle.ru/'
                    },
                    {
                        text: 'Возможности',
                        href: '/promo/features/'
                    },
                    {
                        text: 'HR-Блог',
                        href: 'https://www.workle.ru/content/hr-blog/'
                    },
                    {
                        text: 'Блог об удаленной работе',
                        href: '/publications/'
                    },
                    {
                        text: 'Контакты',
                        href: '/promo/contacts/'
                    },
                    {
                        text: 'Ответы на вопросы',
                        href: 'https://www.workle.ru/content/populyarnye-voprosy/'
                    }
                ],
                sectionLinks: [
                    [
                        {
                            text: 'Работа на дому',
                            href: '/content/rabota-na-domu/'
                        },
                        {
                            text: 'Работа для мам',
                            href: '/content/rabota-dlya-mam-v-dekrete/'
                        },
                        {
                            text: 'Работа для студентов',
                            href: '/content/rabota-dlya-studentov-na-domu/'
                        },
                        {
                            text: 'Работа для инвалидов',
                            href: '/content/rabota-dlya-invalidov-na-domu/'
                        },
                        {
                            text: 'Специалист по туризму удаленно',
                            href: '/content/tourism-specialist/'
                        },
                        {
                            text: 'Специалист по страхованию удаленно',
                            href: '/content/insurance-specialist/'
                        },
                        {
                            text: 'Специалист по финансам удаленно',
                            href: '/content/finance-specialist/'
                        },
                        {
                            text: 'Пассивный доход в интернете',
                            href: '/content/passivniy-dohod-v-internete/'
                        },
                        {
                            text: 'Электронный полис ОСАГО онлайн',
                            href: '/content/kupit-e-osago-online/'
                        },
                        {
                            text: 'Полис ОСАГО онлайн',
                            href: '/content/kupit-polis-osago-online/'
                        },
                        {
                            text: 'Пакетные туры',
                            href: '/content/paketnie-turi/'
                        }
                    ],
                    [
                        {
                            text: 'Условия использования',
                            href: '/resources/doc/offer.htm'
                        },
                        {
                            text: 'Политика конфиденциальности',
                            href: '/resources/doc/personal-data-processing-policy.htm'
                        },
                        {
                            text: 'Умный колл-центр для бизнеса',
                            href: '/content/b2b-call-center/?w_starter'
                        }
                    ],
                    [
                        {
                            text: 'Реклама на сайте',
                            href: '/content/advertising/'
                        },
                        {
                            text: 'Пресс-центр',
                            href: '/press-center/'
                        },
                        {
                            text: 'Сотрудничество',
                            href: '/content/partners_new/'
                        }
                    ]
                ],
                social: [
                    {
                        name: 'vk',
                        link: 'https://vk.com/iworkle',
                        alias: 'vk'
                    },
                    {
                        name: 'odnoklassniki',
                        link: 'https://ok.ru/workle',
                        alias: 'odnoklassniki'
                    },
                    {
                        name: 'telegram',
                        link: 'https://t.me/workle_ru',
                        alias: 'telegram'
                    },
                    {
                        name: 'youtube',
                        link: 'https://www.youtube.com/channel/UCAbFa0AJ7MJ0G8eZZEBI56g',
                        alias: 'youtube'
                    }
                ]
            }
        },
        methods: {
            getDate () {
                const date = new Date()

                return `© 2011-${date.getFullYear()}.`
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
        padding: 24px 20px 6px;
        max-width: 1170px;
        margin: auto;
        font-size: 14px;

        @media (min-width: 480px) {
            padding: 24px 72px 6px 73px;
        }

        @media (min-width: 768px) {
            padding: 24px 20px 6px;
        }

        &__credits {
            padding-top: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: 768px) {
                flex-direction: row;
                justify-content: space-between;
            }

            @media (min-width: 1024px) {
                padding-top: 30px;
            }
        }
    }

    .skolkovo {
        display: flex;
        width: 100%;

        &__svg {
            width: 56px;
            height: 40px;

            @media (min-width: 768px) {
                width: 84px;
                height: 60px;
            }
        }

        &__text {
            line-height: 150%;
            padding-left: 12px;

            @media (min-width: 768px) {
                width: 241px;
                align-self: center;
            }
        }
    }

    .social {
        display: flex;
        justify-content: space-between;
        padding: 24px 0 30px;
        width: 280px;

        @media (min-width: 768px) {
            padding: 0;
            width: 415px;
        }

        &__item {
            width: 38px;
            height: 38px;
            background: #333;
            border-radius: 50%;
            display: flex;
            padding: 10px;

            &:hover {
                background: #0ca9ef;
            }
        }

        &__svg {
            fill: #fff;
        }
    }

    .credits {
        color: #979797;
        text-align: center;
    }

    .links {
        &--border {
            border-bottom: 1px solid #dadada;
            padding-bottom: 10px;
        }

        &--padding {
            padding-top: 10px;
            padding-bottom: 12px;
        }

        &__item {
            font-size: 16px;
            display: flex;
            align-items: center;
            text-decoration: none;
            display: inline-block;

            &--black {
                padding-right: 20px;
                padding-bottom: 14px;
                font-size: 16px;
                line-height: 100%;
                color: #333;

                &:hover {
                    color: #0ca9ef
                }
            }

            &--grey {
                color: #979797;
                padding-right: 12px;
                padding-bottom: 12px;
                font-size: 14px;

                &:hover {
                    color: #333
                }
            }
        }
    }

    .recommendation-technologies {
        color: #979797;
        font-size: 16px;
        line-height: 130%;

        @media (min-width: 768px) {
            text-align: center;
        }

        &--padding-top {
            padding-top: 10px;
        }

        &__link {
            text-decoration: underline;
            color: inherit;
            transition: .3s;

            &:hover {
                color: #333
            }
        }
    }

    .section-links {
        padding-bottom: 24px;
    }
</style>
